import {
  App,
  assertEnvVar,
  getFeedbackMailto,
  H5GroveProvider,
} from '@h5web/app';
import { useMemo } from 'react';
import { useSearchParams } from 'wouter';

const URL = import.meta.env.VITE_H5GROVE_URL || '';
const FEEDBACK_EMAIL = import.meta.env.VITE_FEEDBACK_EMAIL || '';

function Viewer() {
  assertEnvVar(URL, 'VITE_H5GROVE_URL');
  assertEnvVar(FEEDBACK_EMAIL, 'VITE_FEEDBACK_EMAIL');

  const [searchParams] = useSearchParams();

  const sessionId = searchParams.get('sessionId');
  const datafileId = searchParams.get('datafileId');
  const filepath = searchParams.get('name');
  const initialPath = searchParams.get('initialPath');
  const wide = searchParams.get('wide');

  if (!sessionId || !datafileId || !filepath) {
    throw new Error('Missing URL parameter(s)');
  }

  const axiosConfig = useMemo(
    () => ({ params: { sessionId, datafileId } }),
    [sessionId, datafileId],
  );

  return (
    <H5GroveProvider url={URL} filepath={filepath} axiosConfig={axiosConfig}>
      <App
        initialPath={initialPath || undefined}
        sidebarOpen={wide === null}
        getFeedbackURL={(context) => getFeedbackMailto(context, FEEDBACK_EMAIL)}
      />
    </H5GroveProvider>
  );
}

export default Viewer;
